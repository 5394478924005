<template lang="pug">
  v-row.login.align-stretch
    v-col.login-left.d-flex.align-center(cols="12" md="6")
      v-img.login-left-image.mx-auto(:src="logoSrc" max-width="50%" )
    v-col.login-right.d-flex.align-center(cols="12" md="6")
      v-container
        v-row
          v-tabs(centered, fixed-tabs).activeTabs
            v-tab(@click="tabChange(0)") 2FA CREDENTIALS
            v-tab(@click="tabChange(1)") VERIFICATION
        v-row.justify-center(v-if="page === 0")
          v-col(cols="8")
            .font-weight-medium.text-color--dark1.text-center {{ messages[0] }}
          v-col(cols="8")
            v-img.login-left-image.mx-auto(:src="secret2fa.qrCode" max-width="90%" )
          v-col(cols="8")
            v-card
              v-card-text
                v-row.align-stretch
                  v-col(cols="1") 
                    v-tooltip(top)
                      template(v-slot:activator="{attrs, on}")
                        v-icon(v-bind="attrs" @click="copyToClipboard" v-on="on") mdi-content-copy
                      span {{ tooltip }}
                  v-col(cols="11")                 
                    div.p-fixed.ml-1.mt-1.d-flex.overflow-x-auto {{ secret2fa.key }}
        v-row.justify-center(v-if="page === 1")
          v-col(cols="10" v-if="error2fa")
            v-alert(type="error" text) Incorrect code
          v-col(cols="8")
            .font-weight-medium.text-color--dark1.text-center {{ messages[1] }} 
          v-col(cols="7")
            v-otp-input(length="6", height="45", border-radies="10", type="number", plain, @change="getOtpInput")
          v-col(cols="7")
            v-btn.white--text(
              block
              color="#000138"
              type="submit"
              :disabled="loginLoading"
              @click="loginAndRedirect"
            ) VERIFY
</template>
<script>
import logoSrc from '@/assets/image/magnusLogin.svg'
import {EyeIcon, EyeOffIcon} from 'vue-feather-icons'
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: 'Login',

  components: {
    EyeIcon,
    EyeOffIcon
  },

  data() {
    return {
      show: false,
      logoSrc,
      messages: [
        "PLEASE SCAN THE QR CODE WITH YOUR AUTHENTICATOR APP OR PASTE IN THE KEY BELOW THAT",
        "PLEASE ENTER THE CODE THAT IS BEING DISPLAYED ON THE AUTHENTICATOR APP SCREEN"
      ],
      tooltip: "Copy to Clipboard",
      page: 0,
      code: null
    }
  },

  computed: {
    ...mapState("Auth", [
      "error2fa",
      "loginLoading",
      "secret2fa",
      "email",
    ])
  },

  methods: {
    ...mapActions("Auth", [
      "login2fa"
    ]),

    async loginAndRedirect() {
      if (this.code !== null && this.email !== null) {
        const result = await this.login2fa({
          email: this.email,
          code: this.code,
        });
        if (result) {
          this.$router.push("/");
        } else {
          this.code = null;
        }
      }
    },

    async copyToClipboard() {
      await navigator.clipboard.writeText(this.secret2fa.key);
      this.tooltip = "Copied"
      setTimeout(() => this.tooltip = "Copy to Clipboard", 1000);
    },

    tabChange(page) {
      this.page = page;
    },

    async getOtpInput(input) {
      this.code = input;
    }
  }
}
</script>

<style lang="sass" scoped>
.login
  height: 100vh

  &-left
    background-color: #000138

</style>
